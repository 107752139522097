<template>
	<div class="page container">
		<div class="page__info" v-if="getNameString">
			<div class="page__info--title">
				{{ getNameString }}
			</div>
		</div>

		<div>
			<validation-observer
				data-element="form"
				v-if="formLoaded"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Teacher name"
					type="text"
					rules="required"
					v-model="teacherProfile.teacherName"
				/>
				<wysiwyg-field
					name="Bio"
					type="text"
					rules="required"
					v-model="teacherProfile.bio"
				/>
				<select-field
					name="Language"
					rules="required"
					:options="getLanguageOptions"
					v-model="teacherProfile.language"
				/>
				<select-field
					name="Country"
					rules="required"
					:options="getCountryOptions"
					v-model="teacherProfile.countryCode"
				/>
				<select-field
					name="Player ability"
					rules="required"
					:options="getAbilityOptions"
					v-model="teacherProfile.playerAbility"
				/>
				<select-field
					name="Preferred bidding system"
					rules="required"
					:options="getBiddingSystemOptions"
					v-model="teacherProfile.biddingSystem"
				/>
				<checkbox-field
					name="Face to face teacher"
					v-model="teacherProfile.offlineTeacher"
				/>
				<checkbox-field
					name="Runs bridge cruises"
					v-model="teacherProfile.bridgeCruises"
				/>
				<checkbox-field
					name="Runs bridge holidays"
					v-model="teacherProfile.bridgeHolidays"
				/>
				<div data-element="links-field">
					<h2
						data-element="label"
					>
						Profile links
					</h2>
					<div
						data-element="link"
						v-for="(link, index) in teacherProfile.links"
						:key="index"
					>
						<input-field
							name="Link"
							type="url"
							rules="required|url"
							v-model="teacherProfile.links[index].url"
							:id="`link-${index}`"
						/>
						<input-field
							name="Name"
							type="url"
							rules="required"
							v-model="teacherProfile.links[index].name"
							:id="`link-${index}`"
						/>
						<select-field
							name="Type"
							rules="required"
							:options="linkTypeOptions"
							v-model="teacherProfile.links[index].type"
						/>
						<V2Button
							size="sm"
							fit
							@click="removeLinkField(index)"
						>
							Remove link
						</V2Button>
					</div>
					<V2Button
						size="xs"
						fit
						@click="addLinkField"
					>
						{{ getProfileLinkText }}
					</V2Button>
				</div>
				<upload
					fileType="image"
					:helpText="uploadHelpText"
					:form.sync="form"
					:media="teacherProfile.media"
					@deleteMedia="deleteMedia"
				/>
				<actions
					:actions="getActions"
					:isWaiting="isWaiting"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>
	import InputField          from '@/components/forms/InputField';
	import CheckboxField       from '@/components/forms/CheckboxField';
	import SelectField         from '@/components/forms/SelectField';
	import Upload              from '@/components/uploads/Upload';
	import Actions             from '@/components/ui/Actions';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import V2Button           from '@/components/v2/ui/V2Button';
	import api                 from '@/services/api';
	import { languages }       from 'countries-list';
	import { countries }       from 'countries-list';
	import { playerAbility }   from '@/consts.js';
	import { biddingSystems }  from '@/consts.js';

	export default {
		layout: 'v2default',
		metaInfo () {
			if (!this.getNameString) {
				return false;
			}
			return {
				title: this.getNameString
			};
		},
		components: {
			InputField,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			SelectField,
			Upload,
			CheckboxField,
			Actions,
			V2Button
		},
		mixins: [invalidFormMessage],
		data: () => ({
			teacherProfile: undefined,
			formLoaded: false,
			form: undefined,
			isWaiting: undefined,
			uploadHelpText: [
				'Images should be 500px x 500px'
			],
			linkTypeOptions: [
				{
					text: 'Face to face bridge',
					value: 'Face to face bridge'
				},
				{
					text: 'Bridge cruises',
					value: 'Bridge cruises'
				},
				{
					text: 'Bridge holidays',
					value: 'Bridge holidays'
				}
			]
		}),
		computed: {
			getProfileId () {
				return this.$route.params.profileId;
			},
			getTeacherProfile () {
				if (!this.getProfileId) {
					return false;
				}
				return this.teacherProfile;
			},
			getUserId () {
				if (!this.getTeacherProfile) {
					return false;
				}
				return this.getTeacherProfile.userId;
			},
			getUserRoute () {
				if (!this.getUserId) {
					return false;
				}
				return `/admin/users/${this.getUserId}`;
			},
			getNameString () {
				if (!this.getTeacherProfile) {
					return false;
				}
				return this.getTeacherProfile.teacherName;
			},
			getLanguageOptions () {
				return Object.entries(languages).map(([languageCode, language]) => {
					return {
						text: language.name,
						value: languageCode
					};
				});
			},
			getAbilityOptions () {
				return playerAbility.map((ability) => {
					return {
						text: ability,
						value: ability
					};
				});
			},
			getBiddingSystemOptions () {
				return biddingSystems;
			},
			getCountryOptions () {
				return Object.entries(countries).map(([countryCode, country]) => {
					return {
						text: country.name,
						value: countryCode
					};
				});
			},
			getProfileLinkText () {
				if (this.teacherProfile.links.length === 0) {
					return 'Add a profile link';
				}
				return 'Add another profile link';
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							actionId: 'submit',
							btnId: 'submit'
						},
						{
							text: 'Cancel',
							route: this.getUserRoute
						}
					]
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: `/admin/teachers/${this.getProfileId}`,
						text: this.getNameString
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setTeacher();
		},
		methods: {
			onActionClick (actionId) {
				if (!this[actionId] ||
					typeof this[actionId] !== 'function') {
					return;
				}
				this[actionId]();
			},
			addLinkField () {
				this.teacherProfile.links.push({
					url: '',
					name: '',
					type: undefined
				});
			},
			removeLinkField (index) {
				this.teacherProfile.links.splice(index, 1);
			},
			async setTeacher () {
				const teacherProfile = await api.admin.getTeacherProfileById({
					profileId: this.getProfileId
				});
				if (!teacherProfile) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.teacherProfile = teacherProfile;
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async createMedia () {
				const media = await api.admin.createMediaByTeacherProfileId({
					profileId: this.getProfileId,
					media: this.form
				});
				if (!media) {
					return false;
				}
				return media;
			},
			async deleteMedia () {
				if (!this?.teacherProfile?.media?.[0]?.id) {
					return false;
				}
				const response = await api.admin.deleteMediaByTeacherProfileIdAndMediaId({
					profileId: this.getProfileId,
					mediaId: this.teacherProfile.media[0].id
				});
				if (!response) {
					return false;
				}
				this.teacherProfile.media = [];
				this.$store.commit('ui/showNotification', {
					notification: 'Media has been deleted'
				});
			},
			async submit () {
				this.isWaiting = 'submit';
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.isWaiting = undefined;
					this.showInvalidFormMessage();
					return;
				}
				const teacherProfile = await api.admin.updateTeacherProfileById({
					teacherProfile: this.teacherProfile,
					profileId: this.getProfileId
				});
				if (!teacherProfile) {
					this.isWaiting = undefined;
					return false;
				}
				if (this.form) {
					const media = await this.createMedia({
						teacherProfileId: teacherProfile.id
					});
					if (!media) {
						this.$router.push(this.getUserRoute);
						this.$store.commit('ui/showNotification', {
							notification: 'The file could not be uploaded. Please edit the profile and try again.'
						});
						return;
					}
				}
				this.isWaiting = undefined;
				await this.$router.push(this.getUserRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'Teacher profile has been updated'
				});
			}
		}
	};
</script>

<style lang="scss" scoped>

  [data-element='form'] {
    [data-element='links-field'] {
      display:flex;
      flex-direction:column;
      padding:rem(28) rem(12) rem(12);
      border:1px solid $c-brand-grey-lighter-4;
      border-radius:4px;
      [data-element='link'] {
        display:flex;
        flex-direction:column;
        flex-wrap:wrap;
        width:100%;
        margin:rem(12) 0 0;
        padding:0 rem(12) rem(20);
        border:1px solid $c-brand-grey-lighter-4;
        border-radius:4px;
        [data-component='input-field'] {
          width:100%;
        }
        >.btn {
          flex-grow:0;
          flex-shrink:0;
          width:auto;
          margin:rem(12) auto auto 0;
        }
      }
      >.btn {
        flex-grow:0;
        flex-shrink:0;
        width:auto;
        margin:rem(12) auto auto 0;
      }
    }
  }

</style>
